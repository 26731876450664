import React from "react";
import "../App.css";
import "@aws-amplify/ui-react/styles.css";
import { useContext } from "react";
import { View } from "@aws-amplify/ui-react";
import Grid from "@mui/material/Grid";

import { FilteredPeopleContext } from "../contexts/filteredpeoplecontext";

import { UserSignedInAndSubscriptionActive } from "../components/util/UserStatusUtil";
import PersonCard from "./PersonCard";
import { CognitoUserContext } from "../contexts/cognitousercontext";

export default function SlugSearchResults() {
  const { filteredPeople } = useContext(FilteredPeopleContext);

  const { cognitoUser } = useContext(CognitoUserContext);
  //console.log("EMAP in SSR DEFAULT  is ", enumMap);

  //console.log("COGNITOUSER IN SSR", cognitoUser);

  const signedInAndSubscriptionActive =
    UserSignedInAndSubscriptionActive(cognitoUser);
  const blur = signedInAndSubscriptionActive ? 0 : 8;
  const blurFilter = `blur(${blur}px)`;

  return (
    <View className="App">
      <Grid
        container
        spacing={1}
        sx={{ filter: blurFilter }}
        alignItems="center"
        justifyContent="center"
      >
        {filteredPeople.map((person) => (
          <Grid key={person.external_id} item xs="auto">
            <PersonCard
              key={person.external_id}
              person={person}
              active={signedInAndSubscriptionActive}
            />
          </Grid>
        ))}
      </Grid>
    </View>
  );
}
